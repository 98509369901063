@import "~bootstrap/scss/bootstrap";

$black: rgb(15, 20, 25);
$presBlue: #55ACEE;
$presOrange: #FF783F;
$labelGreyText: #4E5154;
$grey95: #f2f2f2;
$grey90: #e6e6e6;
$darkMode: #121212;
$darkModeGrey: #333436;
$lightGrey: lightgrey;
$transparentDarkBackground: rgba(0, 0, 0, 0.8);
$mainGrey: #94948c;
$greyBackground: #f7f9fa;
$lightBackground: #f4f4f4;
$primaryText: #1a1a1a;
$white: white;

body {
  background-color: $white  !important;
  font-family: 'Open Sans' !important;
}

.back-arrow {
  font-size: 24px;
  margin-left: 10px;
}

.is-sticky {
  position: fixed !important;
  top: 0 !important;
  padding: 10px 0 10px !important;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: $white  !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.no-content {
  padding-top: 30%;

  p {
    font-size: 16px;
    text-align: center;
  }

  a {
    background-color: #FF783F;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: 500;

    &:hover {
      color: white;
    }
  }
}

.row-justify-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-app-links {
  display: none;
}

.social-share-wrapper {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;

  .social-share-container {
    background-color: white;
    padding: 18px 20px;
    border-radius: 3px;
    z-index: 15;

    .share-button {
      margin: 5px;
    }

    .link-wrapper {
      margin-top: 16px;
      border: 1px solid $lightGrey;
      padding: 5px 10px;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        border: 0;
        font-size: 14px;
      }

      .copy-button {
        color: $orange;
        font-weight: 500;
        background-color: transparent;
        border: 0;
      }
    }

    .copy-link-success-message {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 14px;
    }

    .share-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .close-icon {
        padding-bottom: 6px;
        cursor: pointer;

        svg {
          font-size: 20px;
        }
      }
    }
  }
}


ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

.navbar-wrapper {
  width: 20%;
  display: flex;
  justify-content: end;

  .my-navbar {
    position: fixed;
    top: 0.5rem;
    width: 14%;
    display: flex;
    flex-direction: column;

    a {
      color: #999;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      font-size: 20px;

      .nav-icon {
        font-size: 25px;
      }

      span {
        margin-left: 14px;
      }

      .logo {
        // width: 31px;
        border-radius: 8px;
        background-color: #121212;
        padding: 6px 16px;
        font-size: 22px;
        color: white;
        font-family: "Old Standard TT", serif;
        font-weight: 600;
        font-style: normal;

        .dot {
          padding: 0;
          color: $presOrange;
          margin-left: 2px;
        }
      }

      .link-container {
        position: relative;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .link-content {
          padding: 12px;
        }

        .link-content:hover {
          background-color: #f2f2f2;
          border-radius: 50px;
        }

        .logo-link:hover {
          background-color: transparent;
        }
      }
    }

    .active-nav-link {
      color: black;
      font-weight: bold;
    }

    .pres-logo {
      height: 30px;
      width: 30px;
      border-radius: 30px;
    }
  }

  .topic-categories {
    position: fixed;
    width: 14%;
    display: flex;
    flex-direction: column;

    .topic-categories-wrapper {
      margin: 20px 18px 0 8px;
      padding-top: 6px;
      border-top: 1px solid #e6e6e6;

      div:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .desktop-app-links {
    position: fixed;
    bottom: 40px;
    width: 14%;
    display: flex;
    flex-direction: column;

    div {
      .app-link {
        width: 140px;
        margin: 5px;
        padding: 2px;
        border-radius: 5px;
        object-fit: fill;
        display: block;
        cursor: pointer;
      }
    }
  }
}

.top-mobile-bar {
  background-color: $labelGreyText  !important;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 15;
  width: 100%;
  z-index: 100;
  margin-bottom: 20px;

  .left-content {
    display: flex;
    align-items: center;

    p {
      color: white;
      font-size: 14px;
      margin: 0;
      margin-left: 8px;
      line-height: 1px;
      line-height: 19px;
    }

    p:first-of-type {
      font-size: 15px;
      font-weight: bold;
    }

    img {
      border-radius: 10px;
      height: 40px;
    }
  }

  button {
    padding: 5px 14px;
    font-weight: normal;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: $presOrange;
  }
}

.root-container {
  width: 100%;
  max-width: 1400px;
  display: flex;
  margin: 0 auto;
}

.right-wrapper {
  width: 40%;

  .desktop-topics-wrapper {
    display: flex;
    margin-left: 50px;

    .footer {
      margin-top: 25px;
      padding: 5px;
      font-size: 13px;

      a {
        text-decoration: none;
        color: rgb(33, 37, 41);
        text-align: right;

        &:hover {
          text-decoration: underline;
        }
      }

      .social-container {
        text-align: center;
        margin-top: 10px;

        .fa-stack {
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }

    .desktop-topics {
      position: fixed;
      padding-top: 1.5rem;

      .num-of-topics {
        color: $presOrange;
        font-size: 13px;
        margin-top: -3px;
      }

      svg {
        font-size: 13px;
        margin-left: 2px;
      }

      h2 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      >div:first-of-type {
        max-height: calc(100vh - 80px);
        overflow-y: scroll;
        padding-bottom: 30px;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
      }

      >div:first-of-type::-webkit-scrollbar {
        display: none;
      }

      .topic-item {
        &:hover {
          // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          text-decoration: underline;
          text-decoration-color: #94948c;
        }
      }
    }
  }
}

.primary-button {
  background-color: $presOrange;
  width: 100%;
  font-size: 1.1rem;
  font-weight: bold;
}

.button {
  display: inline-block;
  line-height: 1.5;
  color: white;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem;
  border-radius: 2rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.disabled-button {
  background-color: $lightGrey;
}

.content-wrapper {
  width: 80%;
  height: 100%;
  min-height: 100vh;

  .iFrame-wrapper {

    .header {
      position: fixed;
      top: 0;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      background-color: white;

      .back-arrow {
        font-size: 22px;
        color: $black;
        margin-left: 8px;
      }
    }
  }

  .content {
    display: flex;
    padding-bottom: 4rem;

    >div:first-of-type {
      position: relative;
      width: 60%;

      .load-more-loader {
        position: absolute;
        top: 0;
        width: 100%;
        height: 96vh;
        align-items: flex-end;
      }
    }

    .title-button-div {
      position: sticky;
      top: 0;
      background-color: $white;

      .title-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
      }

      .source-types-wrapper {
        position: relative;

        >div:first-of-type {
          overflow-x: auto;
          padding: 18px 0 15px 0;
          -ms-overflow-style: none;
          /* IE and Edge */
          scrollbar-width: none;
          /* Firefox */

          .source-types-div {
            display: inline-block;
            white-space: nowrap;

            span {
              padding: 6px 16px;
              border: 1px solid #bfbdbd;
              border-radius: 50px;
              margin-left: 5px;
              background-color: white;
            }

            .active-source-type {
              background-color: $presOrange;
              border-color: $presOrange;
              color: white;
            }
          }

          .left-arrow,
          .right-arrow {
            position: absolute;
            font-size: 26px;
            top: 0px;
            pointer-events: auto;
            -webkit-box-direction: normal;
            margin: 0;
            color: rgba(var(--i1d, 38, 38, 38), 1);
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
            font-size: 14px;
            line-height: 18px;
            background: 0 0;
            border: 0;
            justify-self: center;
            outline: 0;
            padding: 16px 12px;
            cursor: pointer;

            .chevron-icon {
              font-size: 20px;
              color: black;
            }
          }

          .left-arrow {
            left: 0;
            background-image: linear-gradient(to right, rgba(242, 242, 242, 1), rgba(242, 242, 242, 0.9), rgba(242, 242, 242, 0.8));
          }

          .right-arrow {
            right: 0;
            background-image: linear-gradient(to left, rgba(242, 242, 242, 1), rgba(242, 242, 242, 0.9), rgba(242, 242, 242, 0.8));
          }
        }

        div:first-of-type::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .arrow-up-icon {
      font-size: 35px;
      position: fixed;
      bottom: 30px;
      right: 20px;
      color: $presBlue;
    }

    .app-link-content {
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        text-decoration: none;
        color: $presOrange;
        padding-bottom: 2px;
        border-bottom: 1px solid $presOrange;
        font-size: 16px;
      }
    }
  }

  #_sources {
    >div:first-of-type {
      width: 100%;
      max-width: 1000px;
      margin-right: 50px;
    }

    .search-form {
      margin-left: 5px;
      margin-right: 5px;
    }

    .continue-button {
      padding: 7px 24px;
      font-weight: normal;
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 1px;
      background-color: $presOrange;
      margin-right: 5px;

      &:hover {
        color: white;
      }
    }

    .disabled-link {
      background-color: $lightGrey;
    }

    .welcome-message-div {
      padding-left: 10px;
      padding-right: 10px;

      p {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px;
      }

      >p:first-of-type {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 4px;

        span {
          color: $presOrange;
        }
      }
    }
  }

  /* Sources page */
  .sources-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    margin-left: 0px;
    margin-right: 0px;

    .source-col {
      margin: 0.5rem 0;
      text-align: center;
      padding-left: 5px;
      padding-right: 5px;

      .source-card {
        border: 1px solid $lightGrey;
        border-radius: 10px;
        padding: 10px;
        height: 190px;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;

        .source-logo {
          width: 60px;
          height: 60px;
          border: 1px solid $lightGrey;
          padding: 0;
        }

        .source-title {
          margin-top: 10px;
        }

        .follow-button-div {
          border: 1px solid $presOrange;
          border-radius: 50px;
          margin-bottom: 5px;

          .follow-button {
            background-color: transparent;
            color: $black;
            padding: 1px 0;
            font-weight: 500;

            span {
              align-items: center;
              display: flex;

              svg {
                margin-right: 3px;
              }
            }
          }
        }

        .selected-follow-button-div {
          background-color: $presOrange;

          .selected-follow-button {
            color: white;
          }
        }

        .remove-button {
          background-color: white;
          color: black;
          border-color: grey;
        }
      }
    }
  }

  .source-logo-wrapper {
    height: 60px;
    width: 60px;
    display: inline-block;
    margin-bottom: 5px;
  }

  /* Shared classes inside content */
  .source-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 2px solid $grey90;
    padding: 3px;
  }

  .active-source-logo {
    background: $presOrange;
    border-radius: 50px;

    .source-logo {
      background-color: white;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      margin: 2px;
      padding: 1px;
    }
  }

  .source-name {
    font-weight: 400;
    text-align: center;
    line-height: 110%;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80px;
  }

  .dodaj-icon {
    border-radius: 50px;
    color: $mainGrey;
  }

  .page-title {
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.4rem;
      font-weight: bold;
      padding: 16px 12px;
      margin-bottom: 0;
      display: inline-block;
    }

    img {
      height: 2.2rem;
      width: 2.2rem;
      border: 2px solid white;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .category-tabs {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 10;

    .category-item {
      border-bottom: 2px solid transparent;
      padding-bottom: 3px;
    }

    .active-tab {
      color: $presOrange;
      border-bottom: 2px solid $presOrange;
    }

    span {
      margin-left: 5px;
    }
  }

  .home-source-item {
    margin: 0.5rem;
    text-align: center;
  }

  .home-sources-wrapper {
    position: relative;

    .home-sources-content {
      position: relative;
      display: flex;
      overflow-x: auto;
      margin-top: 0.5rem;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }

    .left-arrow,
    .right-arrow {
      position: absolute;
      font-size: 26px;
      top: 0px;
      pointer-events: auto;
      -webkit-box-direction: normal;
      margin: 0;
      color: rgba(var(--i1d, 38, 38, 38), 1);
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 18px;
      background: 0 0;
      border: 0;
      justify-self: center;
      outline: 0;
      padding: 16px 12px;
      cursor: pointer;
      height: 100%;

      .chevron-icon {
        font-size: 22px;
        color: black;
      }
    }

    .left-arrow {
      left: 0;
      // background-color: rgba(242, 242, 242, 0.9);
      background-image: linear-gradient(to right, rgba(242, 242, 242, 1), rgba(242, 242, 242, 0.9), rgba(242, 242, 242, 0.8));
    }

    .right-arrow {
      right: 0;
      background-image: linear-gradient(to left, rgba(242, 242, 242, 1), rgba(242, 242, 242, 0.9), rgba(242, 242, 242, 0.8));
      // background-color: rgba(242, 242, 242, 0.9);
    }
  }

  .home-sources-content::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .mobile-categories-wrapper::-webkit-scrollbar {
    display: none;
  }

  .mobile-categories-wrapper {
    display: none;
    overflow-x: auto;
    padding: 0 1rem;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    margin-bottom: 20px;

    position: sticky;
    top: 0;

    .item {
      margin-left: 4px;
      margin-right: 4px;
      padding: 4px 10px;
      border: 1px solid #bfbdbd;
      border-radius: 50px;
    }

    .category-span {
      white-space: nowrap;
      cursor: pointer;
    }

    .active-item {
      border-color: $presOrange;
      background-color: $presOrange;
      color: white;
    }
  }

  .spinner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }

  .news-card-group {
    padding: 0.2rem 1.5rem;

    .link {
      text-decoration: none;
      display: flex;
      justify-content: end;
      color: $presBlue;
    }
  }

  /* Card used on Home page */
  .card {
    // transition: transform .3s;
    border: 1px solid #e6e6e6;
    cursor: pointer;
    border-radius: 15px;
    background-color: white;
    position: relative;
    padding: 22px;
    margin-bottom: 20px;
    box-shadow: 0 0 12px #eee;

    @media screen and (max-width: 768px) {
      padding: 15px 10px;
      margin-bottom: 10px;
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
      margin-left: 10px;
      margin-right: 10px;
    }

        /* News paragrah */
    p {
      font-size: 14px;
      line-height: 1.5em;
      color: rgba(102, 102, 102, 1);
      margin-bottom: 0;
      display: -webkit-box !important;
      text-overflow: clip;
      word-wrap: break-word;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .card-image {
      width: 110px;
      height: 90px;
      object-fit: cover;
      border-radius: 8px;
    }

    .card-title {
      font-size: 1.1rem;
      font-weight: bold;
      line-height: 1.55rem;
      margin-right: 8px;

      &:hover {
        color: $darkMode;
      }
    }

    .cont-cont {
      display: flex;
      justify-content: space-between;
    }

    .card-body-cont {
      flex: 8;
      width: calc(100% - 260px);
    }

    .card-source {
      display: flex;
      align-items: center;
      color: $primaryText;

      img {
        height: 22px;
        margin-right: 5px;
      }

      svg {
        font-size: 18px;
      }

      span {
        font-size: 0.8rem;
        line-height: 1.25rem;
      }

      .portal-name {
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .similar-news-toggle {
      color: $labelGreyText;
      background-color: $lightBackground;
      display: flex;
      align-items: center;
      padding: 10px 16px;
      border-radius: 6px;
      justify-content: center;

      span {
        font-weight: 600;
        font-size: 13px;
      }

      .similar-news-icon {
        font-size: 13px;
        margin-left: 4px;
      }

      &:hover {
        background-color: #e6e6e6;
      }
    }

    .share-news-div {
      padding: 10px 16px;
      display: flex;
      color: $labelGreyText;
      background-color: $lightBackground;
      border-radius: 6px;
      margin-left: 10px;

      .share-news-icon {
        font-size: 20px;
      }

      span {
        font-size: 15px;
        padding-left: 4px;
        font-weight: 600;
      }

      &:hover {
        background-color: #e6e6e6;
      }
    }
  }

  .category-title {
    display: flex;
    align-items: center;

    h2 {
      font-size: 1.2rem;
      color: $presBlue;
      margin: 0;
    }

    .chevron-right {
      color: $presBlue;
      margin-left: 5px;
      font-size: 1.2rem;
      margin-top: 3px;
    }
  }

  .search-page {
    .search-input-div {
      position: relative;
      padding: 0.8rem 4px;
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: $white;
  
      .search-input {
        padding-left: 1.5rem;
  
        &:focus {
          box-shadow: none;
          outline-width: 0;
          border-color: lightgray;
        }
      }
  
      .clear-search-input-icon {
        position: absolute;
        font-size: 1.4rem;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  
    .no-search-result {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: flex-end;
    }

    .search-topics, .search-portals {
      .num-of-topics {
        color: $presOrange;
        font-size: 13px;
        margin-top: -3px;
      }

      svg {
        font-size: 15px;
        margin-left: 2px;
      }

      h2 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 3 columns */
        gap: 1rem; /* Space between grid items */
      }

      .topics-item {
         border: 1px solid #ccc;
         border-radius: 8px;
         transition: transform 0.2s ease;
      }
      
      .item {
        // border: 1px solid #ccc;
        border-radius: 8px;
        transition: transform 0.2s ease;
        display: flex;
      }
      
      .item:hover, .topics-item:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      
      img {
        height: 32px;
        width: 32px;
        border-radius: 50px;
      }
    }

    .search-topics {
      .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 3 columns */
        gap: 1rem; /* Space between grid items */
      }
    }
  }

}










/* Max Mobile */
@media screen and (max-width: 768px) {
  .content-wrapper {
    width: 100%;

    .card {
      .card-title {
        font-size: 0.9rem;
        line-height: 1.35rem;
      }

      .card-image {
        height: 95px;
        width: 95px;
      }
    }

    .content {

      >div:first-of-type {
        width: 100%;
      }

      .home-sources-wrapper {
        .home-sources-content {
          padding: 0;
        }
      }

      .mobile-categories-wrapper {
        padding: 0;
      }
    }

    .search-page {
      padding: 0 0.2rem;

      .search-topics, .search-portals {
        padding: 0 0.3rem;
  
        .topics-item {
           border: 0px;
           padding: 0 !important;
        }

        .item:hover, .topics-item:hover {
          box-shadow: none;
        }
      }
  
      .search-topics {
        .grid {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      .search-portals {
        .grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  .mobile-app-links {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    background-color: $transparentDarkBackground;

    >div:first-of-type {
      background-color: white;
      padding: 20px 10px;
      padding-bottom: 75px;
      width: 100%;
      border-radius: 20px;

      p {
        margin-top: 0;
        padding-bottom: 16px;
        border-bottom: 1px solid $lightGrey;
        text-align: center;
        font-weight: bold;
      }

      .link-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        >div {
          display: flex;
          align-items: center;
        }

        .icon {
          height: 35px;
          width: 35px;
          margin-right: 8px;
        }

        .pres-logo {
          border-radius: 50px;
          border: 1px solid $lightGrey;
        }

        span {
          font-weight: bold;
        }

        button {
          width: 110px;
          padding: 6px 8px;
          font-weight: normal;
          font-size: 16px;
          background-color: $presBlue;
        }

        .browser-button {
          background-color: $grey90;
          color: $labelGreyText;
          border: 1px solid $lightGrey;
        }
      }
    }

    .app-link {
      width: 140px;
      margin: 5px;
      padding: 2px;
      border-radius: 5px;
      display: block;
      object-fit: fill;
      cursor: pointer;
    }
  }
}








/* Max Medium */
@media screen and (max-width: 992px) {

  .navbar-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 5;
    background-color: white;
    // border-top: 0.5px solid $labelGreyText;
    box-shadow: $lightGrey 0px 0px 3px 1px;
    padding-right: 0;

    .desktop-app-links {
      display: none;
    }

    .my-navbar {
      position: relative;
      padding: 0;
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
      top: 0;

      a {
        display: block;
        text-align: center;
        font-size: 0.95rem;
        padding: 5px 0;

        .nav-icon {
          font-size: 20px;
        }

        span {
          display: block;
          margin: 0;
        }

        .link-container {
  
          .link-content {
            padding: 0;
          }
  
          .link-content:hover {
            background-color: transparent;
            border-radius: 0;
          }
        }
      }

      .active-nav-link {
        color: $presOrange;
      }
    }

    .topic-categories {
      display: none;
    }
  }

  .right-wrapper {
    display: none;
  }

  .content-wrapper {
    width: 100%;

    .content {
      >div:first-of-type {
        width: 100%;

        .load-more-loader {
          height: 85vh;
        }
      }

      .title-button-div {
        width: 100%;

        .title-button-wrapper {
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      .card {
        .card-body {
          .card-source {
            padding-bottom: 0.4rem;

            img {
              height: 90px;
              width: 120px;
            }

            span {
              font-size: 0.8rem;
              margin-left: 5px;
              font-weight: 500;
            }
          }
        }
      }

      .category-tabs {
        display: none !important;
        margin-bottom: 1rem;
      }

      .m-categories {
        display: block !important;
        position: sticky;
        top: 60px;
        z-index: 10;
        background-color: $white;
        scrollbar-width: none;

        .m-categories-wrapper {
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          overflow-x: auto;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }

          .item {
            padding: 6px 18px;
            margin: 0.8rem 0.3rem 0.3rem 0.3rem;
            border-radius: 40px;
            background-color: $lightBackground;
          }
        }
      }
    }

    .portal-page {
      .category-tabs {
        display: flex !important;
      }
    }
  }
}